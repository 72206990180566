<template>
  <v-container
    class="payment-type payment-type-redirect payment-type-monetaweb payment-type-monetaweb-basko-box"
  >
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div
      class="payment-type-description"
      v-html="paymentType.descr"
      v-if="mode != 'addAuth'"
    ></div>
    <div
      v-else
      class="payment-type-description payment-type-description-add-auth"
      v-html="
        $t(`paymentTypeList.addNewPayemntAuth.${paymentType.paymentTypeId}`)
      "
    ></div>
    <div v-if="options.orderAmount" class="mt-3 mb-1">
      <div
        v-html="
          `${$t('paymentTypeList.orderAmount.text')}
      <strong>${$n(options.orderAmount, 'currency')}</strong>`
        "
      />
      <!-- <strong v-html="options.orderAmount.value" /> -->
    </div>
    <PrebilledAmount
      :mode="mode"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />

    <div
      v-if="
        options.showPrebilledAmountInfoTooltip ||
          options.showPrebilledAmountInfoText
      "
      class="prebilledAmountInfo"
    >
      <!-- shows info in form of tooltip on prebilled.info.text span hover -->
      <v-tooltip
        top
        v-if="options.showPrebilledAmountInfoTooltip"
        max-width="30%"
        content-class="payment-type-tooltip payment-type-monetaweb-tooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="text" v-bind="attrs" v-on="on">
            {{ $t("prebilled.info.text") }}
          </div>
        </template>
        <span>{{ $t("prebilled.info.tooltip") }}</span>
      </v-tooltip>

      <!-- shows info under prebilled.info.text -->
      <v-row v-if="options.showPrebilledAmountHtml" class="prebilled_layout">
        <v-col cols="12" sm="2" md="1" class="prebilled_card">
          <img :src="$t('prebilled.html.img')" />
        </v-col>
        <v-col cols="12" sm="10" md="11">
          <div
            class="primary--text text-bold text--lighten-1 text-uppercase"
            v-html="$t('prebilled.html.text')"
          ></div>
          <div class="text-body-2" v-html="$t('prebilled.html.tooltip')"></div>
        </v-col>
      </v-row>
      <div v-else-if="options.showPrebilledAmountInfoText">
        <div class="primary--text text--lighten-1">
          {{ $t("prebilled.info.text") }}
        </div>
        <div class="text-body-2">{{ $t("prebilled.info.tooltip") }}</div>
      </div>
      <span class="sub">{{ $t("prebilled.info.sub") }}</span>
    </div>

    <PaymentAuthList
      v-if="options.showRememberCard || mode == 'addAuth'"
      @selectPaymentAuth="selectPaymentAuth"
      @addNewCard="addNewCard"
      @toggleRememberCard="toggleRememberCard"
      :paymentTypeId="paymentType.paymentTypeId"
      @paymentAuthList="paymentAuthList"
      :showAddNewCardWarning="options.showAddNewCardWarning"
    />
    <!-- <div class="remember-card-container" v-if="options.showRememberCard">
      <v-btn
        color="primary"
        @click="addNewCard"
        small
        v-if="cardList.length > 0"
      >
        {{ $t("paymentTypeList.addNewCard") }}
      </v-btn>
      <v-checkbox v-model="rememberNewCard" :label="calcRememberNewCard()" />
    </div> -->
    <div
      v-if="$te('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      v-html="$t('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      class="redirect-text"
    ></div>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
        <v-btn
          v-if="options.back.show"
          :color="options.back.color"
          :outlined="options.back.outline"
          :icon="options.back.icon"
          :link="options.back.link"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          :large="options.back.large"
          :class="options.back.class"
        >
          {{ options.back.label }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import PrebilledAmount from "~/components/payment/gateways/PrebilledAmount.vue";
import PaymentAuthList from "~/components/payment/gateways/PaymentAuthList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import gateway from "~/mixins/gateway";

// import OrderService from "~/service/orderService";
// import PaymentTypeService from "~/service/paymentTypeService";

import { payment } from "~/components/payment/gateways/gatewayUtilsService";
import { mapActions } from "vuex";
import recurrentOrderService from "@/commons/service/recurrentOrderService";
// import recurrentOrderService from "@/commons/service/recurrentOrderService";

export default {
  name: "PaymentTypeMonetawebBaskoBox",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: this.options.rememberCardDefaultValue || false,
      response: {},
      cardList: []
    };
  },
  props: {
    recurrentOrderId: { type: Number, required: true }
  },
  components: { PrebilledAmount, PaymentAuthList, ResponseMessage },
  methods: {
    ...mapActions({
      // updateCurrentOrderId: "app/updateCurrentOrderId"
    }),
    async buy() {
      let vm = this;
      vm.loading = true;
      try {
        let addAuthData = await recurrentOrderService.checkPaymentMethod(
          this.recurrentOrderId,
          this.paymentType.paymentTypeId,
          this.selectedPaymentAuthId ? this.selectedPaymentAuthId : undefined,
          {
            remember_card: true
          }
        );
        if (
          addAuthData.data &&
          addAuthData.response &&
          addAuthData.response.status === 0
        ) {
          //aggiungo il CURRENT_ORDER_ID al localStorage
          if (this.selectedPaymentAuthId) {
            this.$router.push({ name: "BaskoBoxThankYou" });
          } else {
            vm.doRedirect(
              addAuthData.data.paymentUrl,
              addAuthData.data.paymentParameters,
              "get",
              this.recurrentOrderId
            );
          }
        }
      } catch (err) {
        console.log("moneta basko box", err);
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      let vm = this;
      vm.loading = true;
      try {
        this.buy();
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    calcRememberNewCard() {
      const rememberNewCard = global.EventBus.$t(
        "paymentTypeList.rememberNewCard"
      );
      if (typeof rememberNewCard === "string") {
        return rememberNewCard;
      } else {
        return this.cardList.length > 0
          ? rememberNewCard.new
          : rememberNewCard.first;
      }
    },
    paymentAuthList(arr) {
      this.cardList = arr;
    }
  }
};
</script>
