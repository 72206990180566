<template>
  <div class="basko-box-payment-method">
    <CategoryTitle :category="categoryPaymentMethod" />

    <v-alert type="info" outlined class="mt-6">{{
      $t("eugenio.paymentMethod.info")
    }}</v-alert>

    <div class="checkout-confirm-order" v-if="cart.cartStatusId == 7">
      <p>
        Per applicare le modifiche apportate all'ordine clicca su 'Conferma
        Modifiche'.
      </p>
      <p class="end-xs">
        <v-btn outlined color="primary" depressed :to="{ name: 'Checkout' }"
          >Indietro</v-btn
        >
        <v-btn color="primary" depressed @click="confirmOrder()">
          Conferma Modifiche
        </v-btn>
      </p>
    </div>

    <div
      class="checkout-payment-detail"
      v-if="
        cart.cartStatusId != 7 &&
          recurrentOrderInfo &&
          recurrentOrderInfo.recurrentOrderId
      "
    >
      <BaskoBoxPaymentTypeList
        :modality="'addAuth'"
        :recurrentOrderId="recurrentOrderInfo.recurrentOrderId"
        :filteredPaymentTypeIds="[12]"
        :options="mergedOptions"
      />
    </div>
  </div>
</template>
<style lang="scss">
.basko-box-payment-method {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import BaskoBoxPaymentTypeList from "./BaskoBoxPaymentTypeList.vue";

import recurrentOrderService from "@/commons/service/recurrentOrderService";

import { mapState } from "vuex";
import merge from "lodash/merge";

export default {
  name: "BaskoBoxPaymentMethod",
  components: {
    CategoryTitle,
    BaskoBoxPaymentTypeList
  },
  data() {
    return {
      loading: true,
      baskoSubscription: null,
      recurrentOrderInfo: null,
      paymentTypes: [],

      categoryPaymentMethod: {
        categoryId: 363541,
        name: "Inserisci metodo di pagamento",
        slug: "basko-box",
        categoryTypeId: "999",
        description: "",
        metaData: {
          category_info: {
            HEADER_ICON: "",
            TITLE: "Inserisci metodo di pagamento"
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isSubscribed() {
      return this.baskoSubscription.recurrentOrders &&
        this.baskoSubscription.recurrentOrders.length
        ? true
        : false;
    },
    mergedOptions() {
      return merge(
        {
          showRememberCard: false,
          showAddNewCardWarning: false
        },
        this.options
      );
    }
  },
  methods: {
    confirmOrder() {
      console.log("confirm order");
    },
    loadPaymentTypes() {},
    loadAvailablePaymentAuth() {},
    async getSubscribe() {
      let res = await recurrentOrderService.subscribed();
      if (res?.response.status === 0) {
        this.baskoSubscription = res.data;

        if (this.isSubscribed) {
          this.recurrentOrderInfo = this.baskoSubscription.recurrentOrders[0];
        }
      }
    }
  },
  async mounted() {
    this.getSubscribe();
    this.loadPaymentTypes();
    this.loadAvailablePaymentAuth();
  }
};
</script>
